var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"white shadow1 py-4 pt-2 px-3 rounded",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{staticClass:"custom-form",on:{"submit":function($event){$event.preventDefault();return _vm.getDamageRecord($event)}}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto","sm":"6"}},[_c('v-row',{staticStyle:{"margin-top":"3px","color":"#000","align-items":"center"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v("Date From")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":""},model:{value:(_vm.damage.dateFrom),callback:function ($$v) {_vm.$set(_vm.damage, "dateFrom", $$v)},expression:"damage.dateFrom"}},'v-text-field',attrs,false),on),[_c('v-icon',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v("mdi-calendar-month")])],1)]}}])},[_c('v-date-picker',{model:{value:(_vm.damage.dateFrom),callback:function ($$v) {_vm.$set(_vm.damage, "dateFrom", $$v)},expression:"damage.dateFrom"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"auto","sm":"6"}},[_c('v-row',{staticClass:"pl-3",staticStyle:{"margin-top":"3px","color":"#000","align-items":"center"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v("Date To")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":""},model:{value:(_vm.damage.dateTo),callback:function ($$v) {_vm.$set(_vm.damage, "dateTo", $$v)},expression:"damage.dateTo"}},'v-text-field',attrs,false),on),[_c('v-icon',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v("mdi-calendar-month")])],1)]}}])},[_c('v-date-picker',{model:{value:(_vm.damage.dateTo),callback:function ($$v) {_vm.$set(_vm.damage, "dateTo", $$v)},expression:"damage.dateTo"}})],1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"auto","sm":"1"}},[_c('v-btn',{attrs:{"type":"submit","dark":"","color":"light-blue darken-2","loading":_vm.loading}},[_vm._v("Search")])],1)],1),_c('v-divider')],1)],1)],1),(_vm.show)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticStyle:{"display":"none"},attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"height":"26px"},on:{"click":function($event){$event.preventDefault();return _vm.print($event)}}},[_vm._v("Print")])],1),_c('v-col',{attrs:{"cols":"12","id":"invoiceContent"}},[_c('v-data-table',{staticClass:"custom-data-table mt-5",attrs:{"show-expand":"","headers":_vm.damageHeaders,"loading":_vm.$store.getters['damage/loadin'],"items":_vm.$store.getters['damage/damages'],"search":_vm.searchDamage,"items-per-page":15},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticStyle:{"border-bottom":"1px solid #ddd !important"},attrs:{"dense":"","color":"white","elevation":0}},[_c('v-toolbar-title',{staticClass:"subtitle-2"},[_vm._v("Damage Report")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-form',{staticClass:"custom-form"},[_c('v-text-field',{staticStyle:{"width":"300px"},attrs:{"outlined":"","dense":"","hide-details":"","placeholder":"Search damage","append-icon":"mdi-magnify"},model:{value:(_vm.searchDamage),callback:function ($$v) {_vm.searchDamage=$$v},expression:"searchDamage"}})],1)],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"success","target":"_blank"},on:{"click":function($event){return _vm.$router.push(("/damage-invoice/" + (item.id)))}}},on),[_vm._v(" mdi-file ")])]}}],null,true)},[_c('span',[_vm._v("Invoice")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.$router.push(("/damage/" + (item.id)))}}},on),[_vm._v("mdi-circle-edit-outline")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"error"},on:{"click":function($event){_vm.damageId = item.id;_vm.$refs.confirmDialog.dialog = true}}},on),[_vm._v("mdi-delete-circle-outline")])]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('table',{staticClass:"details__table"},[_c('tr',[_c('th',[_vm._v("SL No.")]),_c('th',[_vm._v("Product Id")]),_c('th',[_vm._v("Product Name")]),_c('th',[_vm._v("Purchase Rate")]),_c('th',[_vm._v("damage Rate")]),_c('th',[_vm._v("Quantity")]),_c('th',[_vm._v("Total")])]),_vm._l((item.damage_details),function(detail,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(i + 1))]),_c('td',[_vm._v(_vm._s(detail.product.code))]),_c('td',[_vm._v(_vm._s(detail.product.name))]),_c('td',[_vm._v(_vm._s(detail.product.purchase_rate))]),_c('td',[_vm._v(_vm._s(detail.rate))]),_c('td',[_vm._v(_vm._s(detail.quantity))]),_c('td',[_vm._v(_vm._s(detail.total))])])})],2)])]}}],null,true)}),_c('confirm-dialog',{ref:"confirmDialog",on:{"confirm":_vm.deleteDamage}})],1)],1):_c('div',{staticClass:"no_result"},[_c('div',{staticClass:"img"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }